<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="$refs.addStayModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="stays" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="$refs.editStayModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>
                        <b-button variant="danger" @click="removeStay(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2">
                        <img v-if="props.formattedRow[props.column.field].icon && props.formattedRow[props.column.field].icon.length > 0" :src="'/api/management/v1/image/' +props.formattedRow[props.column.field].icon" style="max-width: 100%; max-height: 15px;" class="rounded-sm mr-1" alt="Image"/>
                        <span>{{props.formattedRow[props.column.field].name}}</span>
                    </span>
                    <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <AddStayModal ref="addStayModal" :rooms="rooms" :languages="languages" v-on:stayAdded="loadData"/>

        <EditStayModal ref="editStayModal" :rooms="rooms" :languages="languages" v-on:staySaved="loadData"/>
    </div>
</template>
<script>
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddStayModal from '@/views/Stay/AddStayModal'
    import EditStayModal from '@/views/Stay/EditStayModal'

    export default {
        components: {
            EditStayModal,
            AddStayModal,
            BOverlay,
            BCard,
            BButton,
            BBadge,
            BasicTable
        },
        props: {
            displayType: {
                type: String,
                required: false,
                default: 'all'
            }
        },
        data() {
            return {

                dataLoaded: false,
                stays: [],
                rooms: [],
                languages: [],

                columns: [
                    {
                        label: 'ERP Id',
                        displayType: 0,
                        field: 'erp_id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search ERP Id'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Check in',
                        displayType: 0,
                        field: 'check_in',
                        formatFn: (time) => this.moment(time).format('YYYY/MM/DD HH:mm'),
                        filterOptions: {
                            filterFn: (data, filter) => this.moment(data).format('YYYY/MM/DD HH:mm').includes(filter),
                            enabled: true,
                            placeholder: 'Check in'
                        }
                    },
                    {
                        label: 'Check out',
                        displayType: 0,
                        field: 'check_out',
                        formatFn: (time) => this.moment(time).format('YYYY/MM/DD HH:mm'),
                        filterOptions: {
                            filterFn: (data, filter) => this.moment(data).format('YYYY/MM/DD HH:mm').includes(filter),
                            enabled: true,
                            placeholder: 'Check out'
                        }
                    },
                    {
                        label: 'Room',
                        displayType: 0,
                        field: this.roomRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Room'
                        }
                    },
                    {
                        label: 'Language',
                        displayType: 2,
                        field: 'language_id',
                        formatFn: this.languageRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Language'
                        }
                    },
                    {
                        label: 'Show welcome screen',
                        displayType: 3,
                        field(rowObj) {
                            return (rowObj.show_welcome_screen) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/stay?display=${  this.displayType}`)
                loadPromise.then(function(response) {
                    thisIns.stays = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const roomsPromise = this.$http.get('/api/management/v1/room')
                roomsPromise.then(function(response) {
                    thisIns.rooms = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const languagesPromise = this.$http.get('/api/management/v1/language')
                languagesPromise.then(function(response) {
                    thisIns.languages = [{id: '000000000000000000000000', name: 'Default', icon: '', label: 'Test'}].concat(response.data)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, roomsPromise, languagesPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeStay(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/stay/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Stay removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            roomRenderer(rowObj) {
                return this.getRoomName(rowObj.room_id)
            },
            getRoomName(roomId) {
                const room = this.rooms.find(room => room.id === roomId)
                return (room) ? `${room.location  } - ${  room.name}` : 'Unknown'
            },
            languageRenderer(languageId) {
                return this.languages.find(language => language.id === languageId) || {icon: '', name: 'Unknown'}
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>