<template>
    <b-modal title="Edit stay" v-model="editModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>ERP Id:</label>
                <b-form-input
                    type="text"
                    placeholder="ERP Id"
                    v-model="editObject.erp_id"
                />
            </b-form-group>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="editObject.name"
                />
            </b-form-group>
            <b-form-group>
                <b-form-checkbox v-model="editObject.show_welcome_screen">Show welcome screen</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label>Check in:</label>
                <flat-pickr
                    v-model="editObject.check_in"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                />
            </b-form-group>
            <b-form-group>
                <label>Check out:</label>
                <flat-pickr
                    v-model="editObject.check_out"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                />
            </b-form-group>
            <b-form-group>
                <label>Room:</label>
                <v-select
                    v-model="editObject.room_id"
                    :reduce="room => room.id"
                    :filter="filterRooms"
                    label="name"
                    :options="rooms"
                >
                    <template slot="option" slot-scope="room">
                        <span>{{room.location + ' - ' + room.name}}</span>
                    </template>

                    <template slot="selected-option" slot-scope="room">
                        <span>{{room.location + ' - ' + room.name}}</span>
                    </template>
                </v-select>
            </b-form-group>
            <b-form-group>
                <label>Language:</label>
                <v-select
                    v-model="editObject.language_id"
                    :reduce="language => language.id"
                    :filter="filterLanguages"
                    label="name"
                    :options="languages"
                >
                    <template slot="option" slot-scope="language">
                        <img v-if="language.icon && language.icon.length > 0" :src="'/api/management/v1/image/' + language.icon" style="max-width: 100%; max-height: 15px;" class="rounded-sm mr-1" alt="Image"/>
                        <span>{{language.name}}</span>
                    </template>

                    <template slot="selected-option" slot-scope="language">
                        <img v-if="language.icon && language.icon.length > 0" :src="'/api/management/v1/image/' + language.icon" style="max-width: 100%; max-height: 15px;" class="rounded-sm mr-1" alt="Image"/>
                        <span>{{language.name}}</span>
                    </template>
                </v-select>

            </b-form-group>
        </template>
        <template #modal-footer>
            <b-button variant="danger" @click="editModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveStay">
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormCheckbox,
            flatPickr,
            vSelect
        },
        props: {
            rooms: {
                type: Array,
                required: true
            },
            languages: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                editObject: {},
                editModalActive: false
            }
        },
        methods: {
            open(stay) {
                this.editObject = JSON.parse(JSON.stringify(stay))
                this.editModalActive = true
            },
            saveStay() {
                const thisIns = this

                const savePromise = this.$http.put(`/api/management/v1/stay/${  this.editObject.id}`, {
                    name: this.editObject.name,
                    erp_id: this.editObject.erp_id,
                    show_welcome_screen: this.editObject.show_welcome_screen,
                    room_id: this.editObject.room_id,
                    language_id: this.editObject.language_id,
                    check_in: this.moment(this.editObject.check_in).format(),
                    check_out: this.moment(this.editObject.check_out).format()
                })
                savePromise.then(function(response) {
                    thisIns.$printSuccess('Stay saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                    thisIns.$emit('staySaved', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            filterRooms(rooms, search) {
                return rooms.filter(room => room.location.toLowerCase().includes(search.toLowerCase()) || room.name.toLowerCase().includes(search.toLowerCase()))
            },
            filterLanguages(languages, search) {
                return languages.filter(language => language.name.toLowerCase().includes(search.toLowerCase()))
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>